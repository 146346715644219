import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquests bolets tenen forma d’orella humana més o menys lobulada, d’uns 8 a 10 cm d’amplada, gelatinosa en temps humit, i d’un color marró rogenc a més negrós. El peu és molt curt o inexistent. L’himeni és llis i l’esporada blanca en massa, més o manco cilíndriques, llises, de 13-22 x 4-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      